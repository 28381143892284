<template>
  <div>
    <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
      <Head>
        <Title v-if="appConfig.appType === WhitelabelAppType.PORTAL">{{
          title
        }}</Title>
        <template v-for="link in head.link" :key="link.id">
          <Link
            :id="link.id"
            :rel="link.rel"
            :href="link.href"
            :hreflang="link.hreflang"
          />
        </template>
        <template v-for="meta in head.meta" :key="meta.id">
          <Meta
            :id="meta.id"
            :property="meta.property"
            :content="meta.content"
          />
        </template>
        <component :is="'style'">{{ brandingStyles }}</component>
      </Head>
      <Body>
        <div ref="relationContainer" class="relationContainer">
          <div
            :class="{
              modalOpened: globalStore.state.modalState === State.OPENED,
            }"
            class="modalBackdrop"
          ></div>

          <Pageheader :config="pageHeaderConfig">
            <PageheaderSearch />
          </Pageheader>

          <main class="v-el-last pageMain">
            <slot />
          </main>

          <ArticleTaglistWrapped
            v-if="
              whlPageType != WhlPageType.DETAIL &&
              whlPageType != WhlPageType.BOOKMARKS &&
              widgetConfigTypeDef?.showSearchFilter &&
              widgetConfigTypeDef?.filterEnabled &&
              widgetConfigTypeDef?.categoryFilterEnabled &&
              !isEmpty(taglist.items)
            "
            :content="taglist"
          />

          <span class="global-placeholder-el-large"></span>

          <PageFooter
            :config="pageFooter.config"
            :content="pageFooter.content"
            :contact="pageFooter.contact"
            :add-event="pageFooter.addEvent"
          />
        </div>
      </Body>
    </Html>
  </div>
</template>

<script setup lang="ts">
import { useElementSize } from '@vueuse/core';
import type { Taglist } from '@components/Article/Taglist/models';
import type { Config as PageheaderConfig } from '@components/Pageheader/models';
import type { CategoryFilter } from '@components/Pageheader/Search/models';
import type { RawLinkTargetFragment } from '@gql/fragments/__generated/RawLinkTarget';
import type { RawWidgetConfigEventDefFragment } from '@gql/fragments/__generated/RawWidgetConfigEventDef';
import type { RawWidgetConfigPoiDefFragment } from '@gql/fragments/__generated/RawWidgetConfigPoiDef';
import type { RawWidgetConfigTourDefFragment } from '@gql/fragments/__generated/RawWidgetConfigTourDef';
import type { AddressbaseFilter, Category, EventFilter } from '@gql/schema';
import type { Nullable } from '@models/CustomUtilityTypes';
import { WhitelabelAppType } from '@models/WhitelabelAppType';
import { WhlModuleType } from '@models/WhlModuleType';
import { WhlPageType } from '@models/WhlPageType';
import { State, useGlobalStore } from '@stores/globalStore';
import isEmpty from '@utils/isEmpty';
import type { Item as LanguageSwitchListItem } from '@components/PageFooter/LanguageSwitch/List/Item/models';
import type { PageFooter } from '@components/PageFooter/models';
import type { Address } from '@components/PageFooter/Contact/Address/models';
import type { List as ContactMetaNavList } from '@components/PageFooter/Contact/MetaNav/List/models';
import type { Item as ContactMetaNavItem } from '@components/PageFooter/Contact/MetaNav/List/Item/models';

const { t } = useI18n();
const searchStore = useSearchStore();
const appConfig = useWhlAppConfig();
const instanceConfig = useWhlInstanceConfig();
const widgetConfig = await useWidgetConfig();
const widgetConfigTypeDef = useWidgetTypeConfig(widgetConfig);
const route = useRoute();
const globalStore = useGlobalStore();
const whlModuleType = useWhlModuleType();
const whlPageType = useWhlPageType();

const relationContainer = ref(null);
const { width } = useElementSize(relationContainer);
globalStore.setContainerWidth(toValue(width));
watch(width, () => globalStore.setContainerWidth(toValue(width)));

const brandingIdentifier = computed(
  (): string =>
    instanceConfig.value?.brandingAlias ??
    instanceConfig.value?.instanceId ??
    'base'
);

// use branding from widget config
const brandingStyles = computed(() => {
  if (appConfig.appType === WhitelabelAppType.PORTAL) {
    return ':root { ' + (widgetConfig.value?.styling ?? '') + ' }';
  } else {
    return ':host { ' + (widgetConfig.value?.styling ?? '') + ' }';
  }
});

const whitelabelBaseUrl =
  window?.__IMXPLATFORM__WIDGET__?.widgetBaseUrl ??
  '/'; /* safe default for portal */

useBrandingStyles(whitelabelBaseUrl, toValue(brandingIdentifier));

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
});

const title = t((route.meta.title as string) ?? 'app.title.dyn');

const languageOptions = computed((): LanguageSwitchListItem[] => {
  return (instanceConfig.value?.enabledLanguages ?? []).map(
    (isoLang: string): LanguageSwitchListItem => {
      return {
        content: {
          label: t('locales.dyn.' + isoLang),
          value: isoLang,
          icon: mapIsoCodeToFlagIcon(isoLang),
        },
      };
    }
  );
});

const lowerCasedWhlModuleType = computed(() =>
  toValue(whlModuleType)?.toLowerCase()
);

const pageHeaderConfig: Ref<PageheaderConfig> = computed(
  (): PageheaderConfig => {
    return {
      title: {
        title:
          whlPageType.value === WhlPageType.BOOKMARKS
            ? t('layouts.default.pageHeaderConfig.title.bookmarks')
            : (widgetConfigTypeDef.value?.header ?? ''),
        subtitle: '',
      },
      hide: {
        search: !widgetConfigTypeDef.value?.showSearchFilter,
        functions: !widgetConfig.value?.quickAccessList,
        title: false,
        logo: !widgetConfig.value?.showLogo,
      },
      useFulltextsearch: true,
      useDatepicker: true,
    };
  }
);

const metaNav = computed(() => {
  const list: ContactMetaNavList = {
    content: null,
    items: (widgetConfig.value?.footerLinkTargets ?? [])
      .map((link: RawLinkTargetFragment): ContactMetaNavItem => {
        return {
          content: {
            text: link.label,
          },
          config: {
            href: link.targetUrl,
            target: link.openInNewPage ? '_blank' : '_self',
          },
        };
      })
      .filter((value: ContactMetaNavItem) => !isEmpty(value)),
    languageOptions: {
      content: null,
      list: {
        items: languageOptions.value,
        content: null,
      },
    },
  };

  if (!isEmpty(widgetConfig.value?.imprintUrl)) {
    list.items?.push({
      content: {
        text: t('footer.metaNav.imprint'),
      },
      config: {
        href: widgetConfig.value?.imprintUrl ?? '',
        target: '_blank',
      },
    });
  }
  if (!isEmpty(widgetConfig.value?.privacyUrl)) {
    list.items?.push({
      content: {
        text: t('footer.metaNav.privacy'),
      },
      config: {
        href: widgetConfig.value?.privacyUrl ?? '',
        target: '_blank',
      },
    });
  }
  return list;
});

const footerContact: Address = {
  content: {
    name: widgetConfig.value?.contactName,
    street: widgetConfig.value?.street,
    streetNo: widgetConfig.value?.streetNo,
    city: widgetConfig.value?.city,
    zipcode: widgetConfig.value?.zipcode,
    email: widgetConfig.value?.email,
  },
};

const pageFooter = computed((): PageFooter => {
  return {
    content: null,
    config: {
      isWidget: appConfig.appType === WhitelabelAppType.WIDGET,
    },
    contact: {
      content: null,
      address: !isEmpty(footerContact)
        ? footerContact
        : {
            content: instanceConfig.value?.footerContact,
          },
      metaNav: {
        content: null,
        list: metaNav.value,
      },
      title: {
        content: {
          title: t('components.pageFooter.contact.title'),
        },
      },
    },
    addEvent: !isEmpty(widgetConfigTypeDef.value?.partnerClientUrl)
      ? {
          content: null,
          config: {
            link: {
              href: widgetConfigTypeDef.value?.partnerClientUrl,
              text: t(
                'footer.metaNav.addRecord.dyn.' +
                  lowerCasedWhlModuleType.value +
                  '.text'
              ),
              target: '_blank',
            },
          },
          title: {
            content: {
              title: t(
                'footer.metaNav.addRecord.dyn.' +
                  lowerCasedWhlModuleType.value +
                  '.title'
              ),
            },
          },
        }
      : null,
  };
});

const filter = computed((): EventFilter | AddressbaseFilter => {
  const whlModuleTypeValue = toValue(whlModuleType);
  if (!whlModuleTypeValue) {
    return {};
  }

  switch (whlModuleTypeValue) {
    case WhlModuleType.EVENT:
      return toValue(searchStore.eventUserFilter);
    case WhlModuleType.POI:
      return toValue(searchStore.poiUserFilter);
    case WhlModuleType.TOUR:
      return toValue(searchStore.tourUserFilter);
  }

  return {};
});

// get facets from backend
const { facets } = (() => {
  if (whlModuleType.value === WhlModuleType.EVENT) {
    const baseFilter = buildEventBaseFilter(widgetConfig);
    return fetchEventFacets(baseFilter, filter, widgetConfig);
  } else if (whlModuleType.value === WhlModuleType.POI) {
    const baseFilter = buildPoiBaseFilter(widgetConfig);
    return fetchPoiFacets(baseFilter, filter, widgetConfig);
  } else if (whlModuleType.value === WhlModuleType.TOUR) {
    const baseFilter = buildTourBaseFilter(widgetConfig);
    return fetchPoiFacets(baseFilter, filter, widgetConfig);
  }
  return { facets: null };
})();

const taglist = computed((): Taglist => {
  // get Union of categories from config and filter out already selected categories
  const categories = computed(() =>
    constructCategoryFilterObject(
      toValue(whlModuleType),
      toValue(widgetConfigTypeDef)
    ).filter(
      (category) =>
        category.id && !searchStore.state.categories.includes(category.id)
    )
  );

  const items = categories.value
    .map((category) => {
      const count = computed(
        () =>
          facets?.value?.find(
            (facet) => (facet.data as Category).id === category.id
          )?.count ?? 0
      );

      return {
        text: category.i18nName!,
        id: category.id!,
        count: count.value,
      };
    })
    .filter((item) => item.count > 0);

  return {
    title: t('event.search.taglist.title.dyn.' + lowerCasedWhlModuleType.value),
    items: items,
  };
});

function constructCategoryFilterObject(
  module: Nullable<WhlModuleType>,
  widgetConfigTypeDef: Nullable<
    | RawWidgetConfigPoiDefFragment
    | RawWidgetConfigEventDefFragment
    | RawWidgetConfigTourDefFragment
  >
): CategoryFilter[] {
  if (isEmpty(module) || isEmpty(widgetConfigTypeDef)) {
    return [];
  }

  let categories: CategoryFilter[] = [];

  if (module === WhlModuleType.POI) {
    categories =
      (
        widgetConfigTypeDef as RawWidgetConfigPoiDefFragment
      ).categoryFilterProductlines
        ?.filter((productline) => productline.id && productline.i18nName)
        .map((productline) => ({
          id: productline.id!,
          i18nName: productline.i18nName!,
        })) ?? [];
  } else if (module === WhlModuleType.EVENT) {
    categories =
      (
        widgetConfigTypeDef as RawWidgetConfigEventDefFragment
      ).categoryFilterCategories
        ?.filter((category) => category.id && category.i18nName)
        .map((category) => ({
          id: category.id!,
          i18nName: category.i18nName!,
        })) ?? [];
  } else if (module === WhlModuleType.TOUR) {
    categories =
      (
        widgetConfigTypeDef as RawWidgetConfigTourDefFragment
      ).activityFilterTourCategories
        ?.filter((category) => category.id && category.i18nName)
        .map((category) => ({
          id: category.id!,
          i18nName: category.i18nName!,
        })) ?? [];
  }
  return categories;
}
</script>
