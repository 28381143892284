import type { AddressbaseFilter, WidgetConfig } from '@gql/schema';
import type {
  BaseFilterInput,
  TourFilterProperty,
} from '@models/BaseFilterInput';
import type { TourSearchModel } from '../stores/searchStore';

export default (
  widgetConfig: MaybeRefOrGetter<WidgetConfig>,
  userSearchModel: MaybeRefOrGetter<TourSearchModel>,
  ignoreFields?: MaybeRefOrGetter<TourFilterProperty[]>
): Ref<BaseFilterInput<AddressbaseFilter>> => {
  const baseFilter = buildTourBaseFilter(widgetConfig);
  const userFilter = buildTourUserFilter(userSearchModel, ignoreFields);
  const appearanceFilter = buildAppearanceFilterFromWidgetConfig(widgetConfig);

  return computed((): BaseFilterInput<AddressbaseFilter> => {
    return {
      filter: {
        and: [toValue(baseFilter), toValue(userFilter)],
      },
      appearance: toValue(appearanceFilter),
    };
  });
};
