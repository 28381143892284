<template>
  <ul class="list">
    <PageFooterLanguageSwitchListItem
      v-for="item in items"
      :content="item.content"
      @click="emit('localeSelected', item.content?.value)"
    />
  </ul>
</template>

<script lang="ts" setup>
import type { List } from './models';

const emit = defineEmits(['localeSelected']);

const { items } = defineProps<List>();
</script>

<style src="./List.scss" scoped lang="scss"></style>
