<template>
  <li>
    <NuxtLink
      :to="switchLocalePath(content?.value ?? '')"
      :aria-label="content?.label ?? ''"
      :class="{ active: active }"
      class="item"
    >
      <BaseIcon v-if="content?.icon" :name="content?.icon" class="icon" />

      <small class="text">
        {{ content?.label ?? '' }}
      </small>
    </NuxtLink>
  </li>
</template>

<script lang="ts" setup>
import type { Item } from './models';

const switchLocalePath = useSwitchLocalePath();
const { locale } = useI18n();

const { content } = defineProps<Item>();

const active = computed(() => locale.value === content?.value);
</script>

<style src="./Item.scss" scoped lang="scss"></style>
