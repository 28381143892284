<template>
  <div class="filter">
    <ArticleAccordion
      v-if="
        widgetConfigTypeDef?.categoryFilterEnabled ||
        widgetConfigTypeDef?.criterionFilterEnabled ||
        widgetConfigTypeDef?.locationFilterEnabled
      "
    >
      <!-- CATEGORY FILTER -->
      <ArticleAccordionItem
        v-if="widgetConfigTypeDef?.categoryFilterEnabled"
        :text="
          t(
            'components.pageheader.search.item.dropdown.filter.filter-event.categories.text'
          )
        "
      >
        <div class="content horizontalPadding">
          <ArticleAccordion>
            <!-- Categories with groups -->
            <template v-for="item in categoriesWithGroups" :key="item.id">
              <ArticleAccordionItem
                v-model="selectedParentCategories"
                :text="item.label"
                :small="true"
                :has-checkbox="true"
                :value="item.id"
                @selected="toggleGroupSelection(item.children, true)"
                @unselected="toggleGroupSelection(item.children, false)"
              >
                <InputCheckboxList
                  v-model="selectedItems.categories"
                  :items="item.children"
                  :name="'grouped-categories-' + item.id"
                />
              </ArticleAccordionItem>
            </template>

            <!-- Categories without groups -->
            <InputCheckboxList
              v-if="!isEmpty(categoriesWithoutGroups)"
              v-model="selectedItems.categories"
              :items="categoriesWithoutGroups"
              name="ungrouped-categories"
            />
          </ArticleAccordion>
        </div>
      </ArticleAccordionItem>

      <!-- ATTRIBUTE FILTER -->
      <ArticleAccordionItem
        v-if="widgetConfigTypeDef?.criterionFilterEnabled"
        :text="
          t(
            'components.pageheader.search.item.dropdown.filter.filter-event.criteria.text'
          )
        "
      >
        <div class="content">
          <InputPickerMulti
            v-model="selectedItems.attributes"
            :items="attributes"
            name="attributes"
          />
        </div>
      </ArticleAccordionItem>

      <!-- LOCATION FILTER -->
      <ArticleAccordionItem
        v-if="widgetConfigTypeDef?.locationFilterEnabled"
        :text="
          t(
            'components.pageheader.search.item.dropdown.filter.filter-event.location.text'
          )
        "
      >
        <div class="content">
          <PageheaderSearchItemDropdownFilterDistance
            v-model="selectedItems.location.location"
          />

          <!-- <Input-CheckboxList
            v-model="selectedItems.location.infrastructures"
            label="Verkehrsinfrastruktur (im Umkreis von 2km)"
            :items="model?.location.infrastructures"
            name="infrastructures"
            /> -->
        </div>
      </ArticleAccordionItem>
    </ArticleAccordion>

    <!-- PARTICULARITIES FILTER -->
    <InputCheckboxList
      v-model="modelDate.additional.categories"
      :label="t('components.pageheader.search.item.dropdown.filter.event')"
      :items="particularities"
      class="lower-checkboxes"
      name="particularities"
    />

    <PageheaderSearchItemDropdownFilterFooter
      :content="
        t(
          'components.pageheader.search.item.dropdown.filter.filter-event.footer',
          totalCount
        )
      "
      @apply="emit('apply')"
    />
  </div>
</template>

<script lang="ts" setup>
import type { WidgetConfigEventDef } from '@gql/schema';
import { EventFilterProperty } from '@models/BaseFilterInput';
import { WhlModuleType } from '@models/WhlModuleType';
import type { DateFilter, FilterModel } from '../../../models';
import type { BaseLocationType } from '@models/BaseLocation';
import isEmpty from '@utils/isEmpty';

const { t } = useI18n();

const emit = defineEmits(['apply']);

const model = defineModel<FilterModel>();
const modelDate = defineModel<DateFilter>('date');

const widgetConfig = await useWidgetConfig();
const widgetConfigTypeDef = useWidgetTypeConfig(
  widgetConfig
) as Ref<WidgetConfigEventDef>;

const searchStore = useSearchStore();

const searchStateFromCurrentFormData = mapFilterToSearchModel(
  () => searchStore.state.search.join(' '),
  modelDate,
  model
);

/* PARTICULARITIES */
const particularities = computed(() => {
  const widgetConfigTypeDefValue = toValue(widgetConfigTypeDef);

  const items = [];

  if (widgetConfigTypeDefValue) {
    if (widgetConfigTypeDefValue.type === WhlModuleType.EVENT) {
      if (widgetConfigTypeDefValue.oneOffEventsEnabled) {
        items.push({ label: t('filter.events.onlyoneoffs'), value: 'single' });
      }
      if (widgetConfigTypeDefValue.freeEntryEventsEnabled) {
        items.push({
          label: t('filter.events.onlyfreeadmission'),
          value: 'free-of-charge',
        });
      }
    }
  }

  return items;
});

const { totalCount, categoryFacets, criteriaFacets } = fetchEventFilterFacets(
  widgetConfig,
  computed(() => searchStateFromCurrentFormData.value.search.join(' ')),
  computed(
    () =>
      toValue(buildEventFilter(widgetConfig, searchStateFromCurrentFormData))
        ?.filter
  ),
  computed(
    () =>
      toValue(
        buildEventFilter(widgetConfig, searchStateFromCurrentFormData, [
          EventFilterProperty.CATEGORY,
        ])
      )?.filter
  ),
  computed(
    () =>
      toValue(
        buildEventFilter(widgetConfig, searchStateFromCurrentFormData, [
          EventFilterProperty.CRITERION,
        ])
      )?.filter
  ),
  toValue(widgetConfigTypeDef)?.categoryFilterCategories?.map((_) => _.id),
  toValue(widgetConfigTypeDef)?.criterionFilterCriteria?.map((_) => _.id)
);

/* CATEGORIES */
const allCategories = useCategoryFilter(
  () => model.value?.categories,
  categoryFacets
);

const categoriesWithGroups: Ref<CategoryFilterItem[]> = computed(() => {
  const allCategoriesValue = toValue(allCategories) ?? [];
  return allCategoriesValue.filter((item) => (item.children ?? []).length > 0);
});

const categoriesWithoutGroups: Ref<Omit<CategoryFilterItem, 'children'>[]> =
  computed(() => {
    const allCategoriesValue = toValue(allCategories) ?? [];
    return allCategoriesValue.filter(
      (item) => (item.children ?? []).length === 0
    );
  });

/* ATTRIBUTES */
const attributes = computed(() => {
  const facetData = toValue(criteriaFacets);

  return model.value?.attributes
    .map((attribute) => {
      return {
        label: attribute.i18nName + ' (' + (facetData[attribute.id] ?? 0) + ')',
        value: attribute.id,
      };
    })
    .filter((attribute): attribute is { label: string; value: number } => {
      return isDefined(attribute.label) && isDefined(attribute.value);
    });
});

const selectedItems = ref<{
  categories: number[];
  attributes: number[];
  location: {
    infrastructures: number[];
    location: {
      id: number | undefined;
      type: BaseLocationType | undefined;
      name: string | undefined;
      longitude: number | undefined;
      latitude: number | undefined;
    };
  };
}>({
  categories: [],
  attributes: [],
  location: {
    infrastructures: [],
    location: {
      id: undefined,
      type: undefined,
      name: undefined,
      longitude: undefined,
      latitude: undefined,
    },
  },
});

// Watch for changes in the model and update selectedItems accordingly (model -> selectedItems)
watch(
  model,
  (newValue) => {
    if (newValue) {
      selectedItems.value.categories = newValue.categories
        .filter((category) => category.selected)
        .map((category) => category.id);
      selectedItems.value.attributes = newValue.attributes
        .filter((attribute) => attribute.selected)
        .map((attribute) => attribute.id);
    }
  },
  { deep: true, immediate: true }
);

// Watch for changes in selectedItems and update the model accordingly (selectedItems -> model)
watch(
  selectedItems,
  (newValue) => {
    if (model.value) {
      model.value.categories.forEach((category) => {
        category.selected = newValue.categories.includes(category.id);
      });
      model.value.attributes.forEach((attribute) => {
        attribute.selected = newValue.attributes.includes(attribute.id);
      });
      model.value.location.location.id = newValue.location.location.id;
      model.value.location.location.type = newValue.location.location.type;
      model.value.location.location.name = newValue.location.location.name;
      model.value.location.location.longitude =
        newValue.location.location.longitude;
      model.value.location.location.latitude =
        newValue.location.location.latitude;
    }
  },
  { deep: true }
);

const selectedParentCategories = ref<number[]>([]);
watch(
  [selectedItems, categoriesWithGroups],
  ([newItems, newParentCategories]) => {
    const resultIds = [];

    for (let i = 0; i < newParentCategories.length; i++) {
      const parentCategory = newParentCategories[i];
      const selectedChildren = (parentCategory.children ?? []).filter(
        (child) => {
          return newItems.categories.includes(child.id);
        }
      );

      if (selectedChildren.length === parentCategory.children.length) {
        resultIds.push(parentCategory.id);
      }
    }

    selectedParentCategories.value = resultIds;
  },
  { deep: true, immediate: true }
);

function toggleGroupSelection(
  items: Array<{ value: number }>,
  selected: boolean
) {
  const itemValues = items.map((item) => Number(item.value));
  const itemsToAdd = itemValues.filter(
    (value) => !selectedItems.value.categories.includes(value)
  );
  if (selected) {
    selectedItems.value.categories = [
      ...selectedItems.value.categories,
      ...itemsToAdd,
    ];
  } else {
    selectedItems.value.categories = selectedItems.value.categories.filter(
      (value) => !itemValues.includes(value)
    );
  }
}
</script>

<style src="./Filter.scss" scoped lang="scss"></style>
