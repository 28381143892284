<template>
  <div class="distance">
    <div class="input">
      <InputText
        v-model="location"
        :placeholder="
          $t(
            'components.pageheader.search.item.dropdown.filter.distance.input-text.placeholder'
          )
        "
        :disabled="disabled"
        icon="ion:search"
        @input="open = true"
      />

      <button type="button" class="button" @click="handleClick">
        <ButtonBase :content="{ icon: 'ion:locate', text: '' }" />
      </button>
    </div>

    <PageheaderSearchItemDropdownFilterDistanceAutoSuggest
      v-if="open"
      v-click-outside="() => (open = false)"
      :content="{ items: sortedSuggestions }"
      @select="handleSelection"
    />

    <!--    <Input-Checkbox-->
    <!--      :label="-->
    <!--        $t(-->
    <!--          'components.pageheader.search.item.dropdown.filter.distance.input-checkbox.label'-->
    <!--        )-->
    <!--      "-->
    <!--    />-->
  </div>
</template>

<script lang="ts" setup>
import { type Location, BaseLocationType } from '@models/BaseLocation';
import type { AllNullable, Nullable } from '@models/CustomUtilityTypes';
import reverseGeoCodeCoordinates from '@utils/reverseGeoCodeCoordinates';
import type { LocationItem } from './models';
import { WhlModuleType } from '@models/WhlModuleType';
import type {
  AddressbaseFieldFacetResult,
  EventFieldFacetResult,
} from '@gql/schema';

const instanceConfig = useWhlInstanceConfig();
const widgetConfig = await useWidgetConfig();
const whlModuleType = useWhlModuleType();

const model = defineModel<
  AllNullable<{
    id: number;
    type: BaseLocationType;
    name: string;
    latitude: number;
    longitude: number;
  }>
>();

defineProps<{
  disabled?: boolean;
}>();

const location = ref<string>(useSearchStore().state.locationName || '');

const open = ref<boolean>(false);

const handleSelection = (data: LocationItem) => {
  open.value = false;
  location.value = data.label!;
  model.value = {
    id: data.id,
    type: data.type,
    name: data.label,
    latitude: undefined,
    longitude: undefined,
  };
};

const baseFilter = computed(() => {
  if (
    whlModuleType.value === WhlModuleType.POI ||
    whlModuleType.value === WhlModuleType.TOUR
  ) {
    return toValue(buildPoiBaseFilter(widgetConfig));
  } else if (whlModuleType.value === WhlModuleType.EVENT) {
    return toValue(buildEventBaseFilter(widgetConfig));
  }
  return {};
});

const suggestions = await (async (): Promise<LocationItem[]> => {
  let addressPoiGroups: Nullable<
    EventFieldFacetResult | AddressbaseFieldFacetResult
  >;
  let locations: Nullable<EventFieldFacetResult | AddressbaseFieldFacetResult>;
  let regions: Nullable<EventFieldFacetResult | AddressbaseFieldFacetResult>;

  if (
    whlModuleType.value === WhlModuleType.POI ||
    whlModuleType.value === WhlModuleType.TOUR
  ) {
    await fetchAddressbaseAutosuggestLocations(widgetConfig, baseFilter).then(
      (res) => {
        const addressbaseLocations = res.addressbaseLocations.value || [];
        locations = addressbaseLocations.find(
          (item) => item.field === 'LOCATION'
        );
        regions = addressbaseLocations.find((item) => item.field === 'REGION');
        addressPoiGroups = addressbaseLocations.find(
          (item) => item.field === 'POI_GROUP'
        );
      }
    );
  } else if (whlModuleType.value === WhlModuleType.EVENT) {
    await fetchEventAutosuggestLocations(widgetConfig, baseFilter).then(
      (res) => {
        const eventLocations = res.eventLocations.value || [];
        locations = eventLocations.find((item) => item.field === 'LOCATION');
        regions = eventLocations.find((item) => item.field === 'REGION');
        addressPoiGroups = eventLocations.find(
          (item) => item.field === 'POI_GROUP'
        );
      }
    );
  }

  const transform = (
    items: Nullable<EventFieldFacetResult | AddressbaseFieldFacetResult>
  ) => {
    return (
      items?.facets
        ?.filter((item) =>
          instanceConfig.value.pageheader.search.locationSearchWhitelist.includes(
            item.data._entityType as BaseLocationType
          )
        )
        ?.map((item) => ({
          type: item.data._entityType as BaseLocationType,
          label:
            item.data._entityType === BaseLocationType.LOCATION
              ? (item.data as Location).i18nName || (item.data as Location).name
              : (item.data as Location).i18nName,
          id: (item.data as Location).id,
        }))
        .filter((item) => item.type && item.label && item.id) || []
    );
  };

  const mergedLocations = [
    ...transform(locations),
    ...transform(addressPoiGroups),
    ...transform(regions),
  ];

  return mergedLocations;
})();

const sortedSuggestions = computed(() => {
  return suggestions
    .map((suggestion) => ({
      ...suggestion,
      score: sortUtils(location.value, suggestion.label!),
    }))
    .filter((suggestion) => suggestion.score >= 0.1)
    .sort((a, b) => b.score - a.score);
});

const handleClick = async () => {
  const { data: currentUserPosition, error: currentUserPositionError } =
    await rawTryCatch(getCurrentUserLocation());

  if (currentUserPositionError) {
    console.error('Could not get current user location');
    return;
  }

  const { data: displayName, error: displayNameError } = await rawTryCatch(
    reverseGeoCodeCoordinates(
      currentUserPosition,
      instanceConfig.value?.map?.maptoolkitApiKey
    )
  );

  if (displayNameError) {
    console.error('Could not reverse geocode user location');
    return;
  }

  location.value = displayName;
  model.value = {
    id: undefined,
    type: undefined,
    name: displayName,
    latitude: currentUserPosition.latitude,
    longitude: currentUserPosition.longitude,
  };
};
</script>

<style src="./Distance.scss" scoped lang="scss"></style>
