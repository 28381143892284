import { useQuery } from '@urql/vue';
import type { MaybeRef } from 'vue';
import type { RawWidgetConfigFragment } from '@gql/fragments/__generated/RawWidgetConfig';
import {
  AddressbaseFilterFacetsDocument,
  type AddressbaseFilterFacetsQuery,
  type AddressbaseFilterFacetsQueryVariables,
} from '@gql/queries/__generated/AddressbaseFilterFacets';
import type { AddressbaseFilter } from '@gql/schema';

export default (
  widgetConfig: MaybeRef<RawWidgetConfigFragment | null>,
  filter: MaybeRefOrGetter<AddressbaseFilter>,
  filterWithoutCategories: MaybeRefOrGetter<AddressbaseFilter>,
  allowedCategoryProductlines: MaybeRefOrGetter<number[]>,
  filterWithoutCriteria: MaybeRefOrGetter<AddressbaseFilter>,
  allowedCriteriaProductlines: MaybeRefOrGetter<number[]>
) => {
  const { locale } = useI18n();

  const { data } = useQuery<
    AddressbaseFilterFacetsQuery,
    AddressbaseFilterFacetsQueryVariables
  >({
    query: AddressbaseFilterFacetsDocument,
    variables: {
      language: locale,
      appearance: buildAppearanceFilterFromWidgetConfig(widgetConfig),
      filter: filter,
      filterWithoutCategories: filterWithoutCategories,
      filterWithoutCriteria: filterWithoutCriteria,
      allowedCategoryProductlines: allowedCategoryProductlines,
      allowedCriteriaProductlines: allowedCriteriaProductlines,
    },
  });

  const totalCount = computed(() => {
    return data.value?.totalRecords?.pagination?.totalRecords ?? -1;
  });

  const categoryFacets = computed((): Record<number, number> => {
    return data.value?.categoryFacets?.facets?.fields[0]?.facets?.reduce(
      (acc, facet) => {
        acc[facet.data.id] = facet.count;
        return acc;
      },
      {}
    );
  });

  const criteriaFacets = computed((): Record<number, number> => {
    return data.value?.criteriaFacets?.facets?.fields[0]?.facets?.reduce(
      (acc, facet) => {
        acc[facet.data.id] = facet.count;
        return acc;
      },
      {}
    );
  });

  return {
    totalCount,
    categoryFacets,
    criteriaFacets,
  };
};
