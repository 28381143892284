import { useQuery } from '@urql/vue';
import type { RawWidgetConfigFragment } from '@gql/fragments/__generated/RawWidgetConfig';
import type { EventFilter } from '@gql/schema';
import {
  EventLocationsAutosuggestDocument,
  type EventLocationsAutosuggestQuery,
} from '@gql/queries/__generated/EventLocationsAutosuggest';
import type { Nullable } from '@models/CustomUtilityTypes';

export default async (
  widgetConfig: MaybeRef<Nullable<RawWidgetConfigFragment>>,
  baseFilter: MaybeRef<EventFilter> = ref<EventFilter>({})
) => {
  const { fetching, data, error } =
    await useQuery<EventLocationsAutosuggestQuery>({
      query: EventLocationsAutosuggestDocument,
      variables: {
        eventBaseFilter: baseFilter,
        appearance: buildAppearanceFilterFromWidgetConfig(widgetConfig),
      },
    });

  const eventLocations = computed(() => {
    return data.value?.events?.facets.fields;
  });

  return { fetching, error, eventLocations };
};
