import type { WhitelabelAppConfig } from '@models/WhitelabelAppConfig';
import { WhitelabelAppType } from '@models/WhitelabelAppType';

export default (baseUrl: string, brandingIdentifier: string) => {
  const appConfig = useWhlAppConfig() as WhitelabelAppConfig;

  if (appConfig.appType === WhitelabelAppType.PORTAL) {
    return useHead({
      link: [
        {
          rel: 'stylesheet',
          href:
            baseUrl +
            'assets/css/Branding/' +
            brandingIdentifier +
            '/CSSCustomProps.css',
        },
        {
          rel: 'stylesheet',
          href: baseUrl + 'assets/fonts/' + brandingIdentifier + '.css',
        },
      ],
    });
  } else {
    //  add link tags to shadow dom root for widget mode
    if (appConfig.shadowRoot) {
      const fontStyleLink = document.createElement('link');
      fontStyleLink.rel = 'stylesheet';
      fontStyleLink.href =
        baseUrl + 'assets/fonts/' + brandingIdentifier + '.css';
      appConfig.shadowRoot.prepend(fontStyleLink);

      const brandingStyleLink = document.createElement('link');
      brandingStyleLink.rel = 'stylesheet';
      brandingStyleLink.href =
        baseUrl +
        'assets/css/Branding/' +
        brandingIdentifier +
        '/CSSCustomProps.css';
      appConfig.shadowRoot.prepend(brandingStyleLink);
    }
  }
};
