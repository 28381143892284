<template>
  <div class="datepicker">
    <InputCalendarQuick
      :selected="selectedQuickdateItem"
      @set-date="(date) => handleQuickdate(date)"
    />

    <div class="wrap">
      <VueDatePicker
        ref="datepicker"
        v-model="model"
        :config="{ noSwipe: true }"
        :month-change-on-scroll="false"
        :multi-calendars="multiCalendars"
        :disable-year-select="false"
        :enable-time-picker="false"
        :min-date="new Date()"
        locale="de"
        menu-class-name="datepicker__menu"
        calendar-class-name="dp__calendar_wrap"
        month-name-format="long"
        auto-apply
        focus-start-date
        inline
        range
        @range-start="handleRangeStart"
        @range-end="handleRangeEnd"
        @update-month-year="handleMonthYear"
      >
        <!-- template for single day -->
        <template #day="{ day }">
          <div
            class="datepicker__day"
            @touchstart="handleTouch"
            @touchend="handleTouch"
          >
            <span class="datepicker__day__value">{{ day }}</span>
          </div>
        </template>
      </VueDatePicker>
    </div>
  </div>
</template>

<script lang="ts" setup>
import VueDatePicker, { type DatePickerInstance } from '@vuepic/vue-datepicker';
import type { Nullable } from '@models/CustomUtilityTypes';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { WeekdayIndex } from '@models/Weekdays';
dayjs.extend(isSameOrBefore);
const datepicker = ref<DatePickerInstance>(null);

const model = defineModel<string[]>();
const pendingDate = defineModel<string>('pendingDate');
const numberOfClicks = defineModel<number>('numberOfClicks');

const handleTouch = (event: Event) => {
  event.preventDefault();
};

const handleRangeStart = (date: Date) => {
  pendingDate.value = date.toString();
  numberOfClicks.value = (numberOfClicks.value + 1) % 2;
};

const handleRangeEnd = () => {
  numberOfClicks.value = (numberOfClicks.value + 1) % 2;
};

const selectedQuickdateItem = computed(() =>
  determineQuickdateIndex(model.value)
);

const handleQuickdate = (date: string[]) => {
  if (isEmpty(date)) {
    return;
  }

  if (date.length === 1) {
    date.push(date[0]);
  } else if (date.length > 2) {
    date = date.slice(0, 2);
  }

  model.value = date;
};

const multiCalendars = ref(false);
let resizeObserver: ResizeObserver | null = null;

const registerResizeObserver = (node: Element | null) => {
  if (node) {
    resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        multiCalendars.value = entry.contentRect.width > 800;
      }
    });

    resizeObserver.observe(node);
  }
};

const disableMonthYearSelect = () => {
  const monthButtons = document.querySelectorAll('.dp__month_year_select');
  monthButtons.forEach((button) => {
    button.setAttribute('disabled', 'true');
  });
};

const handleMonthYear = ({ _instance, month, year }) => {
  const date = new Date();
  date.setMonth(month);
  date.setFullYear(year);
  const isDateSameOrBefore = dayjs(date).isSameOrBefore(dayjs(), 'month');
  disablePrevMonthButton(isDateSameOrBefore);
};

const disablePrevMonthButton = (disable) => {
  const prevMonthButton = datepicker.value?.dpWrapMenuRef?.querySelector(
    "[data-dp-element='action-prev']"
  );
  if (prevMonthButton) {
    if (disable) {
      prevMonthButton.setAttribute('disabled', 'true');
    } else {
      prevMonthButton.removeAttribute('disabled');
    }
  }
};

const getVisibleDate = () => {
  if (isEmpty(model.value)) {
    return new Date();
  } else {
    return model.value[0];
  }
};

onUpdated(() => {
  disableMonthYearSelect();
});

onMounted(() => {
  //ToDo: replace querying of relationContainer for globalStore.state.containerWidth
  const relationContainer = document.querySelector('.relationContainer');
  registerResizeObserver(relationContainer);

  /* have to await this here, because buttons my not be there immediately */
  nextTick(() => {
    const isDateSameOrBefore = dayjs(getVisibleDate()).isSameOrBefore(
      dayjs(),
      'month'
    );
    disablePrevMonthButton(isDateSameOrBefore);
  });
});

onUnmounted(() => {
  if (resizeObserver) {
    resizeObserver.disconnect();
  }
});

function determineQuickdateIndex(
  date: Nullable<string[]>
): 0 | 1 | 2 | undefined {
  if (isEmpty(date) || date!.length !== 2) {
    return undefined;
  }

  const [startDate, endDate] = date!.map((d) => dayjs(d));
  const today = dayjs().startOf('day');
  const tomorrow = today.add(1, 'day');

  if (startDate.isSame(endDate, 'day')) {
    if (startDate.isSame(today, 'day')) {
      return 0;
    } else if (startDate.isSame(tomorrow, 'day')) {
      return 1;
    }
  } else if (
    startDate.day() === WeekdayIndex.Saturday &&
    endDate.day() === WeekdayIndex.Sunday
  ) {
    return 2;
  }

  return undefined;
}
</script>

<style src="./DatePicker.scss" scoped lang="scss"></style>
