<template>
  <label :class="{ error: error }" class="input-checkbox">
    <input
      v-model="model"
      :name="name"
      :value="value"
      :disabled="disabled"
      class="input"
      type="checkbox"
      @change="handleChange"
    />

    <BaseIcon class="icon" name="ion:checkmark" />

    <span class="label" v-html="label" />
  </label>
</template>

<script lang="ts" setup>
const emit = defineEmits<{
  change: [isChecked: boolean];
}>();

const model = defineModel<Array<string | number>>();

defineProps<{
  label?: string;
  name?: string;
  value?: string | number;
  error?: boolean;
  disabled?: boolean;
}>();

function handleChange(event: Event): void {
  const isChecked = (event.target as HTMLInputElement).checked;
  emit('change', isChecked);
}
</script>

<style src="./Checkbox.scss" scoped lang="scss"></style>
