import type { CategoryFilter } from '@components/Pageheader/Search/models';
import type { BaseCategoryFilterItem } from '@models/BaseCategoryFilterItem';
import isEmpty from '@utils/isEmpty';

export default (
  flatCategories$: MaybeRefOrGetter<CategoryFilter[]>,
  facetData$: MaybeRefOrGetter<Record<number, number>>
): Ref<BaseCategoryFilterItem[]> => {
  // category tree (without facet data)
  const categoryTree$: Ref<CategoryFilter[]> = computed(
    (): CategoryFilter[] => {
      const flatCategories: CategoryFilter[] = toValue(flatCategories$);

      if (isEmpty(flatCategories)) {
        return [];
      }

      const categoryIds = flatCategories.map((category) => category.id);

      // map categories to their parents
      const rootCategories: Record<number, CategoryFilter> = flatCategories
        .filter(
          (category) =>
            !category.parent || !categoryIds.includes(category.parent.id)
        )
        .reduce((acc, category) => {
          acc[category.id] = {
            ...category,
            children: [],
          };
          return acc;
        }, {});

      // map non-root categories to their parents
      flatCategories
        // filter out root categories
        .filter(
          (category) =>
            category.parent && categoryIds.includes(category.parent.id)
        )
        // ensure that items are already on alphebetical order
        .sort((a, b) => a.i18nName.localeCompare(b.i18nName))
        // add items as child to their parent
        .forEach((category) => {
          const parent = rootCategories[category.parent.id];
          if (parent) {
            if (!parent.children) {
              parent.children = [];
            }
            parent.children.push(category);
          }
        });

      return (
        Object.values(rootCategories)
          // sort root categories
          .sort((a, b) => a.i18nName.localeCompare(b.i18nName))
      );
    }
  );

  // category items (with facet data)
  return computed((): BaseCategoryFilterItem[] => {
    const categoryTree: CategoryFilter[] = toValue(categoryTree$);
    const facetData: Record<number, number> = toValue(facetData$);

    return (categoryTree ?? []).map((item): BaseCategoryFilterItem => {
      const result: Partial<BaseCategoryFilterItem> = {
        id: item.id,
        value: item.id,
        title: item.i18nName,
        facetCount: facetData ? (facetData[item.id] ?? 0) : 0,
      };

      if (item.children?.length > 0) {
        result['label'] = result.title;
        result['children'] = (item.children ?? []).map(
          (child): BaseCategoryFilterItem => {
            const facetCount = facetData ? (facetData[child.id] ?? 0) : 0;

            return {
              id: child.id,
              value: child.id,
              title: child.i18nName,
              facetCount,
              label: `${child.i18nName} (${facetCount})`,
            };
          }
        );
      } else {
        result['label'] = `${result.title} (${result.facetCount})`;
      }

      return result;
    });
  });
};
