<template>
  <div class="item">
    <InputFake
      ref="fakeInput"
      :label="t('components.pageheader.search.item.item-date.input-fake.label')"
      :placeholder="placeholder"
      :value="value"
      icon="ion:calendar-outline"
      @click="emit('toggle', itemId)"
    />

    <PageheaderSearchItemTab
      ref="tab"
      :content="{
        icon: 'ion:calendar-outline',
        text: t('components.pageheader.search.item.item-date.tab.text'),
      }"
      :state="isDateFilterSet ? 'ion:checkmark' : null"
      :active="showDropdown"
      @click="handleClick"
    />

    <PageheaderSearchItemDropdown
      v-if="showDropdown"
      v-focus-first
      v-click-outside="{
        ignore: [fakeInput, tab],
        handler: () => emit('deactivate', itemId),
      }"
      @keyup.esc="emit('deactivate', itemId)"
    >
      <InputCalendar v-model="model" @apply="emit('apply', itemId)" />
    </PageheaderSearchItemDropdown>
  </div>
</template>

<script lang="ts" setup>
import { MediaType } from '../../../../assets/scss/variables';
import type { DateFilter } from '../models';
const { t, d } = useI18n();
const searchStore = useSearchStore();
const globalStore = useGlobalStore();

const fakeInput = ref<HTMLElement | null>(null);
const tab = ref<HTMLElement | null>(null);

const model = defineModel<DateFilter>();

const emit = defineEmits([
  'activate',
  'deactivate',
  'toggle',
  'apply',
  'close',
]);

const placeholder = computed(() => {
  const dateFrom = searchStore.state.dateFrom;
  const dateTo = searchStore.state.dateTo;

  if (!isEmpty(dateFrom) && !isEmpty(dateTo)) {
    return dateFrom === dateTo
      ? d(new Date(dateFrom!), 'short')
      : joinNonEmptyStrings(
          model.value?.date?.selectedDateRange.map((date) =>
            d(new Date(date), 'short')
          ),
          ' - '
        );
  } else {
    return t(
      'components.pageheader.search.item.item-date.input-fake.placeholder'
    );
  }
});

const isDateFilterSet = computed(() => {
  const items = [
    searchStore.state.dateFrom,
    searchStore.state.dateTo,
    searchStore.state.daytime,
  ];

  if (globalStore.state.mediaType !== MediaType.TY) {
    items.push(searchStore.state.hasSingleEvent);
    items.push(searchStore.state.onlyFree);
  }

  return !isEmpty(items);
});

const props = defineProps<{
  itemId: string;
  activeItem: string;
}>();

const value = ref<string>('');
const showDropdown = computed(() => {
  return props.activeItem === props.itemId;
});

const handleClick = () => {
  if (showDropdown.value) {
    emit('close');
  } else {
    emit('toggle', props.itemId);
  }
};
</script>

<style src="./Item.scss" scoped lang="scss"></style>
