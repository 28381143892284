<template>
  <label :for="name" class="item">
    {{ label }}

    <input
      v-model="model"
      :type="type"
      :name="name"
      :value="value"
      :disabled="disabled"
      class="input"
    />
  </label>
</template>

<script lang="ts" setup>
const model = defineModel<Array<string | number>>();

defineProps<{
  type: string;
  label?: string;
  icon?: string;
  value?: string | number;
  name?: string;
  disabled?: boolean;
}>();
</script>

<style src="./Item.scss" scoped lang="scss"></style>
