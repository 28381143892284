<template>
  <picture class="logo">
    <img v-if="logoUrl" :src="logoUrl" class="image" />
  </picture>
</template>

<script setup lang="ts">
const instanceConfig = useWhlInstanceConfig();
const widgetConfig = await useWidgetConfig();

const logoUrl = computed(() => {
  if (!isEmpty(widgetConfig.value?.footerLogoUrl)) {
    return toValue(widgetConfig.value?.footerLogoUrl);
  }

  if (!isEmpty(instanceConfig.value?.footerLogo)) {
    return toValue(
      prefixUrlPathWithBaseURL(instanceConfig.value?.footerLogo || '')
    );
  }

  // default fallback
  return toValue(
    prefixUrlPathWithBaseURL('assets/images/logo/logo.imxplatform.white.svg')
  );
});
</script>

<style src="./Logo.scss" scoped lang="scss"></style>
