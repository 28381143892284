<template>
  <li :class="[{ active: active }, { small: small }]" class="item">
    <ArticleAccordionItemTitle
      v-model="model"
      :text="text"
      :active="active"
      :small="small"
      :value="value"
      :has-checkbox="hasCheckbox"
      @click="toggle"
      @checkbox-click="handleCheckboxChange"
    />

    <div :class="{ active: active }" class="item__content">
      <div :class="{ active: active }" class="item__content__inner">
        <slot />
      </div>
    </div>
  </li>
</template>

<script lang="ts" setup>
const emit = defineEmits(['selected', 'unselected']);

const model = defineModel<Array<string | number>>();

withDefaults(
  defineProps<{
    text: string;
    small?: boolean;
    value?: string | number;
    hasCheckbox?: boolean;
  }>(),
  {
    small: false,
    hasCheckbox: false,
  }
);

const active = ref(false);

const toggle = () => {
  active.value = !active.value;
};

function handleCheckboxChange(isChecked: boolean) {
  if (isChecked) {
    emit('selected');
  } else {
    emit('unselected');
  }
}
</script>

<style src="./Item.scss" scoped lang="scss"></style>
