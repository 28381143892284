//const LEVENSHTEIN_WEIGHT = 0.3;

export function computeScore(query: string, target: string): number {
  if (query === target) return 1;

  let score = 0;

  if (query.startsWith(target) || target.startsWith(query)) {
    score += 0.7;

    // Apply additional score based on the length of the target
    score += Math.min(1 / target.length, 0.2);
  }

  // Lower the weight for includes but apply penalty for distance from the start
  else if (target.includes(query)) {
    const position = target.indexOf(query);
    // Penalize based on how far the match is from the start
    const penalty = position / target.length; // Higher penalty for matches farther from start
    score += Math.max(0.5 - penalty, 0.3); // Base score for includes, but reduce based on position, ensuring it doesn't go below 0.2
  }

  // Calculate similarity based on Levenshtein distance and apply a smaller weight
  /* const similarityScore = levenshteinDistance(query, target);
  score +=
    (1 - similarityScore / Math.max(query.length, target.length)) *
    LEVENSHTEIN_WEIGHT; */

  return score;
}

export function levenshteinDistance(a: string, b: string): number {
  const matrix = Array.from({ length: a.length + 1 }, (_, i) =>
    Array(b.length + 1).fill(i)
  );
  for (let j = 0; j <= b.length; j++) matrix[0][j] = j;

  for (let i = 1; i <= a.length; i++) {
    for (let j = 1; j <= b.length; j++) {
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1,
        matrix[i][j - 1] + 1,
        matrix[i - 1][j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1)
      );
    }
  }

  return matrix[a.length][b.length];
}

export default function (input: string, label: string): number {
  return computeScore(
    input.trim().toLocaleLowerCase(),
    label.trim().toLocaleLowerCase()
  );
}
