import { useQuery } from '@urql/vue';
import type { RawWidgetConfigFragment } from '@gql/fragments/__generated/RawWidgetConfig';
import type { AddressbaseFilter } from '@gql/schema';
import {
  AddressbaseLocationsAutosuggestDocument,
  type AddressbaseLocationsAutosuggestQuery,
} from '@gql/queries/__generated/AddressbaseLocationsAutosuggest';
import type { Nullable } from '@models/CustomUtilityTypes';

export default async (
  widgetConfig: MaybeRef<Nullable<RawWidgetConfigFragment>>,
  baseFilter: MaybeRef<AddressbaseFilter> = ref<AddressbaseFilter>({})
) => {
  const { fetching, data, error } =
    await useQuery<AddressbaseLocationsAutosuggestQuery>({
      query: AddressbaseLocationsAutosuggestDocument,
      variables: {
        addressbaseBaseFilter: baseFilter,
        appearance: buildAppearanceFilterFromWidgetConfig(widgetConfig),
      },
    });

  const addressbaseLocations = computed(() => {
    return data.value?.pois?.facets.fields;
  });

  return { fetching, error, addressbaseLocations };
};
