<template>
  <button :class="{ active: active }" class="tab" type="button">
    <div class="wrap">
      <BaseIcon :name="icon" class="icon" />
      <span class="text">{{ content.text }}</span>

      <span v-if="value" class="value">{{ value }}</span>
      <span v-else-if="state" class="value"
        ><BaseIcon :name="state" class="state"
      /></span>
    </div>
  </button>
</template>

<script lang="ts" setup>
import type { AtomsIconText } from '@models/Atoms';
import type { Nullable } from '@models/CustomUtilityTypes';

const props = defineProps<{
  content: AtomsIconText;
  value?: number;
  state?: Nullable<string>;
  active: boolean;
}>();

const value = computed(() => props.value);

const icon = computed(() => {
  return props.active ? 'ion:close' : props.content.icon;
});
</script>

<style src="./Tab.scss" scoped lang="scss"></style>
