<template>
  <div
    v-click-outside="() => (open = false)"
    :class="{ open: open }"
    class="languageSwitch"
  >
    <button ref="button" type="button" class="value" @click="open = !open">
      <span class="text">{{ activeLanguage?.content?.label }}</span>

      <BaseIcon
        class="icon"
        name="material-symbols:keyboard-arrow-down-rounded"
      />
    </button>

    <PageFooterLanguageSwitchOverlay v-show="open">
      <PageFooterLanguageSwitchList
        v-model="activeLanguage"
        :items="list?.items"
        :content="list?.content"
        @locale-selected="open = false"
        @tab-back="focusButton"
      />
    </PageFooterLanguageSwitchOverlay>
  </div>
</template>

<script lang="ts" setup>
import type { LanguageSwitch } from './models';
import type { Item } from './List/Item/models';

const { locale } = useI18n();

const button = ref<HTMLButtonElement | null>(null);

const open = ref<boolean>(false);

const focusButton = () => {
  if (button.value) {
    button.value.focus();
  }
};

const { list } = defineProps<LanguageSwitch>();

const activeLanguage = computed(() => {
  return (
    list?.items?.find(
      (option: Item): boolean => option.content?.value === locale.value
    ) ?? list?.items?.[0]
  );
});
</script>

<style src="./LanguageSwitch.scss" scoped lang="scss"></style>
