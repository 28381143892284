<template>
  <form
    :class="`search-${whlModuleType}`"
    class="search"
    @submit.prevent=""
    @reset="handleReset"
  >
    <PageheaderSearchItemTerm
      v-if="widgetConfigTypeDef?.whatSearchEnabled"
      v-model="formModel_fulltextsearch"
      :active-item="activeItem"
      :placeholder="termPlaceholder ?? ''"
      class="term"
      item-id="term"
      @keyup.enter="handleToggle('term', 'close', true)"
      @activate="handleToggle('term', 'open')"
      @deactivate="handleToggle('term', 'close')"
      @close="handleToggle('term', 'close')"
    />

    <PageheaderSearchItemDate
      v-if="
        whlModuleType === WhlModuleType.EVENT &&
        (widgetConfigTypeDef as RawWidgetConfigEventDefFragment)
          ?.whenSearchEnabled
      "
      v-model="formModel_date"
      :active-item="activeItem"
      class="date"
      item-id="date"
      @activate="handleToggle('date', 'open')"
      @deactivate="handleToggle('date', 'close')"
      @toggle="handleToggle('date')"
      @close="handleToggle('date', 'close')"
      @apply="handleToggle('date', 'close', true)"
    />

    <PageheaderSearchItemFilter
      v-if="filterEnabledAndAvailable"
      v-model="formModel_filter"
      v-model:date="formModel_date"
      :item-id="filterItemId"
      :whl-module-type="whlModuleType ?? WhlModuleType.EVENT"
      :active-item="activeItem"
      class="filter"
      @activate="handleToggle(filterItemId, 'open')"
      @deactivate="handleToggle(filterItemId, 'close')"
      @toggle="handleToggle(filterItemId)"
      @close="handleToggle(filterItemId, 'close')"
      @apply="handleToggle(filterItemId, 'close', true)"
    />
  </form>
</template>

<script lang="ts" setup>
import { MediaType } from '../../../assets/scss/variables';
import type { RawWidgetConfigEventDefFragment } from '@gql/fragments/__generated/RawWidgetConfigEventDef';
import type { RawWidgetConfigPoiDefFragment } from '@gql/fragments/__generated/RawWidgetConfigPoiDef';
import type { RawWidgetConfigTourDefFragment } from '@gql/fragments/__generated/RawWidgetConfigTourDef';
import type { Nullable } from '@models/CustomUtilityTypes';
import { WhlModuleType } from '@models/WhlModuleType';
import type {
  AttributeFilter,
  CategoryFilter,
  DateFilter,
  FilterModel,
} from './models';
import { State } from '@stores/globalStore';

const DEBOUNCE_DELAY = 500;

const { t } = useI18n();
const widgetConfig = await useWidgetConfig();
const widgetConfigTypeDef = useWidgetTypeConfig(widgetConfig);
const whlModuleType = useWhlModuleType();
const searchStore = useSearchStore();
const globalStore = useGlobalStore();

const props = withDefaults(
  defineProps<{
    filterItemId?: string;
  }>(),
  {
    filterItemId: 'filter',
  }
);

const refreshFilterModel = (): void => {
  formModel_filter.value.categories = constructCategoryFilterObject(
    toValue(whlModuleType),
    toValue(widgetConfigTypeDef)
  );
  formModel_filter.value.attributes = constructAttributeFilterObject(
    toValue(whlModuleType),
    toValue(widgetConfigTypeDef)
  );
};

watch(whlModuleType, refreshFilterModel);
watch(widgetConfigTypeDef, refreshFilterModel);

const filterItemId = computed(() => props.filterItemId);
const activeItem = ref<string>('');

const formModel_fulltextsearch = ref<string>('');
const formModel_date = ref<DateFilter>({
  date: {
    selectedDateRange: [],
    pendingDate: undefined,
  },
  additional: {
    categories: [],
    dayTime: [],
  },
});
const formModel_filter = ref<FilterModel>({
  categories: constructCategoryFilterObject(
    toValue(whlModuleType),
    toValue(widgetConfigTypeDef)
  ),
  attributes: constructAttributeFilterObject(
    toValue(whlModuleType),
    toValue(widgetConfigTypeDef)
  ),
  location: {
    location: {
      id: undefined,
      type: undefined,
      name: undefined,
      latitude: undefined,
      longitude: undefined,
    },
    infrastructures: [],
  },
  tour: {
    length: undefined,
    duration: undefined,
    difficulties: [],
  },
});

const termPlaceholder = computed(() => {
  switch (toValue(whlModuleType)) {
    case WhlModuleType.POI:
      return t('common.sights');
    case WhlModuleType.EVENT:
      return t('common.events');
    case WhlModuleType.TOUR:
      return t('common.tours');
  }
  return undefined;
});

const stateFromFormModel = mapFilterToSearchModel(
  formModel_fulltextsearch,
  formModel_date,
  formModel_filter
);

const filterEnabledAndAvailable = computed(() => {
  const widgetConfigTypeDefValue: RawWidgetConfigPoiDefFragment &
    RawWidgetConfigTourDefFragment &
    RawWidgetConfigEventDefFragment = toValue(widgetConfigTypeDef);

  if (!widgetConfigTypeDefValue?.filterEnabled) {
    return false;
  }

  if (toValue(whlModuleType) === WhlModuleType.EVENT) {
    if (
      widgetConfigTypeDefValue?.categoryFilterEnabled ||
      widgetConfigTypeDefValue?.criterionFilterEnabled ||
      widgetConfigTypeDefValue?.locationFilterEnabled
    ) {
      return true;
    } else if (
      globalStore.state.mediaType === MediaType.TY &&
      (widgetConfigTypeDefValue?.oneOffEventsEnabled ||
        widgetConfigTypeDefValue?.freeEntryEventsEnabled)
    ) {
      return true;
    } else {
      return false;
    }
  }

  return true;
});

const handleSubmit = (): void => {
  searchStore.updateState(toValue(stateFromFormModel), toValue(widgetConfig));
};

const handleToggle = (
  itemId: string,
  forceState?: 'open' | 'close',
  submit = false
): void => {
  if (submit) {
    handleSubmit();
  }
  if (forceState) {
    activeItem.value = forceState === 'close' ? '' : itemId;
    const modalState = forceState === 'close' ? State.CLOSED : State.OPENED;
    globalStore.setModalState(modalState);

    return;
  }

  activeItem.value = activeItem.value === itemId ? '' : itemId;
  if (itemId !== 'term') {
    globalStore.toggleModalState();
  }
};

const handleReset = (): void => {
  searchStore.resetState();
};

const debouncedHandleSubmit = useDebounceFn(handleSubmit, DEBOUNCE_DELAY);

// Trigger automatic handle submit on formModel changes
watch(
  [formModel_fulltextsearch],
  () => {
    if (globalStore.state.mediaType !== MediaType.TY) {
      debouncedHandleSubmit();
    }
  },
  { deep: true }
);

// Watch the searchStore and update formModel values
watch(
  searchStore,
  (newState) => {
    formModel_fulltextsearch.value = newState.state.search.join(' ');

    formModel_filter.value.categories.forEach((category) => {
      category.selected = newState.state.categories.includes(category.id);
    });
    formModel_filter.value.attributes.forEach((attribute) => {
      attribute.selected = newState.state.criteria.includes(attribute.id);
    });
    formModel_filter.value.tour.duration = newState.state.duration;
    formModel_filter.value.tour.length = newState.state.length;
    formModel_filter.value.location.location.id = newState.state.locationId;
    formModel_filter.value.location.location.type = newState.state.locationType;
    formModel_filter.value.location.location.name = newState.state.locationName;
    formModel_filter.value.particularities = [
      newState.state.onlyFree ? 'free-of-charge' : undefined,
      newState.state.hasSingleEvent ? 'single' : undefined,
    ].filter(Boolean) as string[];

    formModel_date.value.date.selectedDateRange = [
      newState.state.dateFrom,
      newState.state.dateTo,
    ];
    formModel_date.value.date.pendingDate = newState.state.dateFrom;
    formModel_date.value.additional.dayTime = newState.state.daytime;
    formModel_date.value.additional.categories = [
      newState.state.onlyFree ? 'free-of-charge' : undefined,
      newState.state.hasSingleEvent ? 'single' : undefined,
    ].filter(Boolean) as string[];
  },
  { deep: true, immediate: true }
);

function constructCategoryFilterObject(
  module: Nullable<WhlModuleType>,
  widgetConfigTypeDef: Nullable<
    | RawWidgetConfigPoiDefFragment
    | RawWidgetConfigEventDefFragment
    | RawWidgetConfigTourDefFragment
  >
): CategoryFilter[] {
  if (!module || !widgetConfigTypeDef) {
    return [];
  }

  let categories: CategoryFilter[] = [];

  if (module === WhlModuleType.POI) {
    categories =
      (
        widgetConfigTypeDef as RawWidgetConfigPoiDefFragment
      ).categoryFilterProductlines
        ?.filter((item) => item.id && item.i18nName)
        .map((item) => ({
          id: item.id!,
          i18nName: item.i18nName!,
          parent: item.parent,
          selected: false,
        })) ?? [];
  } else if (module === WhlModuleType.EVENT) {
    categories =
      (
        widgetConfigTypeDef as RawWidgetConfigEventDefFragment
      ).categoryFilterCategories
        ?.filter((item) => item.id && item.i18nName)
        .map((item) => ({
          id: item.id!,
          i18nName: item.i18nName!,
          parent: item.parent,
          selected: false,
        })) ?? [];
  } else if (module === WhlModuleType.TOUR) {
    categories =
      (
        widgetConfigTypeDef as RawWidgetConfigTourDefFragment
      ).activityFilterTourCategories
        ?.filter((item) => item.id && item.i18nName)
        .map((item) => ({
          id: item.id!,
          i18nName: item.i18nName!,
          parent: item.parent,
          selected: false,
        })) ?? [];
  }
  return categories;
}

function constructAttributeFilterObject(
  module: Nullable<WhlModuleType>,
  widgetConfigTypeDef: Nullable<
    | RawWidgetConfigPoiDefFragment
    | RawWidgetConfigEventDefFragment
    | RawWidgetConfigTourDefFragment
  >
): AttributeFilter[] {
  if (!module || !widgetConfigTypeDef) {
    return [];
  }

  let attributes: AttributeFilter[] = [];

  if (module === WhlModuleType.POI) {
    attributes =
      (
        widgetConfigTypeDef as RawWidgetConfigPoiDefFragment
      ).criterionFilterProductlines
        ?.filter((attribute) => attribute.id && attribute.i18nName)
        .map((attribute) => ({
          id: attribute.id!,
          i18nName: attribute.i18nName!,
          selected: false,
        })) ?? [];
  } else if (module === WhlModuleType.EVENT) {
    attributes =
      (
        widgetConfigTypeDef as RawWidgetConfigEventDefFragment
      ).criterionFilterCriteria
        ?.filter((attribute) => attribute.id && attribute.i18nName)
        .map((attribute) => ({
          id: attribute.id!,
          i18nName: attribute.i18nName!,
          selected: false,
        })) ?? [];
  } else if (module === WhlModuleType.TOUR) {
    attributes =
      (
        widgetConfigTypeDef as RawWidgetConfigTourDefFragment
      ).attributeFilterTourAttributes
        ?.filter((attribute) => attribute.id && attribute.i18nName)
        .map((attribute) => ({
          id: attribute.id!,
          i18nName: attribute.i18nName!,
          selected: false,
        })) ?? [];
  }

  return attributes;
}
</script>

<style scoped lang="scss">
@import './Search.scss';
</style>
