<template>
  <div class="title">
    <picture v-if="!hideLogo" class="picture">
      <img
        :src="logoUrl"
        :alt="content.title + ' ' + content.subtitle"
        class="logo"
      />
    </picture>

    <div class="text">
      <span class="main">{{ content.title }} {{ content.subtitle }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Title } from '../models';

const instanceConfig = useWhlInstanceConfig();
const widgetConfig = await useWidgetConfig();

defineProps<{
  content: Title;
  hideLogo?: boolean;
}>();

const logoUrl = computed(() => {
  if (!isEmpty(widgetConfig.value?.logoUrl)) {
    return widgetConfig.value.logoUrl;
  }

  if (!isEmpty(instanceConfig.value?.headerLogo)) {
    return toValue(prefixUrlPathWithBaseURL(instanceConfig.value.headerLogo));
  }

  // default
  return toValue(
    prefixUrlPathWithBaseURL('/assets/images/logo/logo.imxplatform.dark.svg')
  );
});
</script>

<style src="./Title.scss" scoped lang="scss"></style>
