<template>
  <button
    :class="[{ active: active }, { small: small }]"
    :aria-label="text"
    class="title"
    type="button"
  >
    <strong v-if="!hasCheckbox" class="title__text" aria-hidden="true">{{
      text
    }}</strong>

    <InputCheckbox
      v-if="hasCheckbox"
      v-model="model"
      :label="text"
      :value="value"
      @change="handleCheckboxChange"
    />

    <BaseIcon
      class="title__arrow"
      name="tabler:chevron-down"
      aria-hidden="true"
    />
  </button>
</template>

<script lang="ts" setup>
const emit = defineEmits(['checkbox-click']);

const model = defineModel<Array<string | number>>();

defineProps<{
  text: string;
  active: boolean;
  small: boolean;
  value?: string | number;
  hasCheckbox?: boolean;
}>();

function handleCheckboxChange(isChecked: boolean) {
  emit('checkbox-click', isChecked);
}
</script>

<style src="./Title.scss" scoped lang="scss"></style>
