import type { EventFilter, WidgetConfig } from '@gql/schema';
import type {
  EventFilterProperty,
  BaseFilterInput,
} from '@models/BaseFilterInput';

export default (
  widgetConfig: MaybeRefOrGetter<WidgetConfig>,
  userSearchModel: MaybeRefOrGetter<SearchModel>,
  ignoreFields?: MaybeRefOrGetter<EventFilterProperty[]>
): Ref<BaseFilterInput<EventFilter>> => {
  const baseFilter = buildEventBaseFilter(widgetConfig);
  const userFilter = buildEventUserFilter(userSearchModel, ignoreFields);
  const appearanceFilter = buildAppearanceFilterFromWidgetConfig(widgetConfig);

  return computed((): BaseFilterInput<EventFilter> => {
    return {
      filter: {
        and: [toValue(baseFilter), toValue(userFilter)],
      },
      appearance: toValue(appearanceFilter),
    };
  });
};
