import { useQuery } from '@urql/vue';
import type { MaybeRef } from 'vue';
import type { RawWidgetConfigFragment } from '@gql/fragments/__generated/RawWidgetConfig';
import {
  TourFilterFacetsDocument,
  type TourFilterFacetsQuery,
  type TourFilterFacetsQueryVariables,
} from '@gql/queries/__generated/TourFilterFacets';
import type { AddressbaseFilter } from '@gql/schema';

export default (
  widgetConfig: MaybeRef<RawWidgetConfigFragment | null>,
  filter: MaybeRefOrGetter<AddressbaseFilter>,
  filterWithoutCategories: MaybeRefOrGetter<AddressbaseFilter>,
  filterWithoutAttributes: MaybeRefOrGetter<AddressbaseFilter>,
  allowedTourCategories?: MaybeRefOrGetter<number[]>,
  allowedTourAttributes?: MaybeRefOrGetter<number[]>
) => {
  const { locale } = useI18n();

  const { data } = useQuery<
    TourFilterFacetsQuery,
    TourFilterFacetsQueryVariables
  >({
    query: TourFilterFacetsDocument,
    variables: {
      language: locale,
      appearance: buildAppearanceFilterFromWidgetConfig(widgetConfig),
      filter: filter,
      filterWithoutCategories: filterWithoutCategories,
      filterWithoutAttributes: filterWithoutAttributes,
      allowedTourCategories: allowedTourCategories,
      allowedTourAttributes: allowedTourAttributes,
    },
  });

  const totalCount = computed(() => {
    return data.value?.totalRecords?.pagination?.totalRecords ?? -1;
  });

  const categoryFacets = computed((): Record<number, number> => {
    return data.value?.categoryFacets?.facets?.fields[0]?.facets?.reduce(
      (acc, facet) => {
        acc[facet.data.id] = facet.count;
        return acc;
      },
      {}
    );
  });

  const attributeFacets = computed((): Record<number, number> => {
    return data.value?.attributeFacets?.facets?.fields[0]?.facets?.reduce(
      (acc, facet) => {
        acc[facet.data.id] = facet.count;
        return acc;
      },
      {}
    );
  });

  return {
    totalCount,
    categoryFacets,
    attributeFacets,
  };
};
